.RequestEditScreen .header {
    display: flex;
    justify-content: space-between;
    margin-left: 250px;
}

.RequestEditScreen .header label {
    font-weight: 700;
}

.RequestEditScreen .header p {
    color: rgb(194, 10, 204);
    font-weight: 700;
}

.RequestEditScreen .path {
    margin-left: 250px;
}

.RequestEditScreen .sidebar {
    background: white;
    min-width: 250px;
    max-width: 250px;
    padding-top: 16px;
    z-index: 1;
}

.RequestEditScreen .main-content {
    width: 100%;
}

.RequestEditScreen .upload-box div {
    text-indent: 0
}

.RequestEditScreen .link-download {
    color: rgb(96, 96, 96);
    font-style: italic;
    text-decoration: none;
}

.RequestEditScreen .link-download svg {
    margin-top: -5px;
}

.RequestEditScreen .MuiTextField-root {
    width: 100%;
}

.RequestEditScreen .MuiFormControl-root {
    width: 100%;
    margin-top: 10px;
}

.RequestEditScreen .MuiPaper-root {
    margin-top: 0;
}

.RequestEditScreen .MuiCardContent-root {
    padding: 0;
}

.RequestEditScreen .Mui-disabled {
    background-color: #f3f3f3;
    -webkit-text-fill-color: rgba(0, 0, 0, 0.75) !important;
}

.RequestEditScreen span.Mui-disabled,
.RequestEditScreen label.Mui-disabled {
    background-color: unset;
}

.RequestEditScreen > div > div > div > div > div > div > div > div {
    width: 100%;
    margin-top: 10px;
}