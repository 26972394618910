/* ForgotPasswordScreen */
.ForgotPasswordScreen {
    width: 100%;
}

.ForgotPasswordScreen .slds-form-element .slds-form-element__label {
    font-weight: bold;
}

.ForgotPasswordScreen .slds-form-element .slds-form-element__label.required:before {
    content: '* ';
    color: red;
}

.ForgotPasswordScreen .slds-button_brand {
    background-color: rgb(194, 10, 204);
    border-color: rgb(194, 10, 204);
}

.ForgotPasswordScreen .slds-button_brand[disabled] {
    background-color: var(--slds-g-color-neutral-base-80, #c9c7c5);
    border-color: var(--slds-g-color-neutral-base-80, #c9c7c5);
    color: var(--slds-g-color-neutral-base-100, white);
}

.ForgotPasswordScreen .slds-button_outline-brand {
    border-color: rgb(194, 10, 204);
    color: rgb(194, 10, 204);
}

.ForgotPasswordScreen .box {
    border: 1px solid #c5ced6;
    border-radius: 0.5rem;
    margin-top: 0.5rem;
    padding: 2rem;
}

.ForgotPasswordScreen .box>p {
    margin-top: 1rem;
}

.ForgotPasswordScreen .box>p:nth-child(1) {
    margin-top: 0;
}

.ForgotPasswordScreen .box div.slds-form-element {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.ForgotPasswordScreen .box>div.slds-form-element:nth-child(1) {
    margin-top: 0;
}

.ForgotPasswordScreen .box ul {
    list-style: none;
    margin-left: 2rem;
}

.ForgotPasswordScreen .box ul li i.bi-check-circle-fill {
    color: green;
}

.ForgotPasswordScreen .box ul li i.bi-x-circle-fill {
    color: red;
}

.ForgotPasswordScreen .box-caption {
    font-size: 1.75rem;
    font-style: italic;
    text-align: center;
    font-weight: bold;
}

.ForgotPasswordScreen .box-absolute-center {
    margin: auto;
}

.ForgotPasswordScreen .icon-email {
    width: 1.25rem;
    height: 1.25rem;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
}

.ForgotPasswordScreen .icon-email use {
    fill: gray;
}

.ForgotPasswordScreen .remark {
    font-size: 0.75rem;
}

@media only screen and (max-width: 748px) {
    .ForgotPasswordScreen {
        width: 100%;
    }
}

/* InvalidResetPasswordEmailScreen */
.InvalidResetPasswordEmailScreen {
    width: 100%;
}

.InvalidResetPasswordEmailScreen .slds-form-element .slds-form-element__label {
    font-weight: bold;
}

.InvalidResetPasswordEmailScreen .slds-form-element .slds-form-element__label.required:before {
    content: '* ';
    color: red;
}

.InvalidResetPasswordEmailScreen .slds-button_brand {
    background-color: rgb(194, 10, 204);
    border-color: rgb(194, 10, 204);
}

.InvalidResetPasswordEmailScreen .slds-button_brand[disabled] {
    background-color: var(--slds-g-color-neutral-base-80, #c9c7c5);
    border-color: var(--slds-g-color-neutral-base-80, #c9c7c5);
    color: var(--slds-g-color-neutral-base-100, white);
}

.InvalidResetPasswordEmailScreen .slds-button_outline-brand {
    border-color: rgb(194, 10, 204);
    color: rgb(194, 10, 204);
}

.InvalidResetPasswordEmailScreen .box {
    border: 1px solid #c5ced6;
    border-radius: 0.5rem;
    padding: 2rem;
}

.InvalidResetPasswordEmailScreen .box>p {
    margin-top: 1rem;
}

.InvalidResetPasswordEmailScreen .box>p:nth-child(1) {
    margin-top: 0;
}

.InvalidResetPasswordEmailScreen .box div.slds-form-element {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.InvalidResetPasswordEmailScreen .box>div.slds-form-element:nth-child(1) {
    margin-top: 0;
}

.InvalidResetPasswordEmailScreen .box ul {
    list-style: none;
    margin-left: 2rem;
}

.InvalidResetPasswordEmailScreen .box ul li i.bi-check-circle-fill {
    color: green;
}

.InvalidResetPasswordEmailScreen .box ul li i.bi-x-circle-fill {
    color: red;
}


.InvalidResetPasswordEmailScreen .box-caption {
    font-size: 1.75em;
    font-style: italic;
    text-align: center;
    padding: 0.5rem 0rem;
    font-weight: bold;
}

.InvalidResetPasswordEmailScreen .box-absolute-center {
    margin: auto;
}

.InvalidResetPasswordEmailScreen .icon-email {
    width: 1.25rem;
    height: 1.25rem;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
}

.InvalidResetPasswordEmailScreen .icon-email use {
    fill: gray;
}

.InvalidResetPasswordEmailScreen .remark {
    font-size: 0.75rem;
}

@media only screen and (max-width: 748px) {
    .InvalidResetPasswordEmailScreen {
        width: 100%;
    }
}

/* ErrorResetPasswordScreen */
.ErrorResetPasswordScreen {
    width: 100%;
}

.ErrorResetPasswordScreen .slds-form-element .slds-form-element__label {
    font-weight: bold;
}

.ErrorResetPasswordScreen .slds-form-element .slds-form-element__label.required:before {
    content: '* ';
    color: red;
}

.ErrorResetPasswordScreen .slds-button_brand {
    background-color: rgb(194, 10, 204);
    border-color: rgb(194, 10, 204);
}

.ErrorResetPasswordScreen .slds-button_brand[disabled] {
    background-color: var(--slds-g-color-neutral-base-80, #c9c7c5);
    border-color: var(--slds-g-color-neutral-base-80, #c9c7c5);
    color: var(--slds-g-color-neutral-base-100, white);
}

.ErrorResetPasswordScreen .slds-button_outline-brand {
    border-color: rgb(194, 10, 204);
    color: rgb(194, 10, 204);
}

.ErrorResetPasswordScreen .box {
    border: 1px solid #c5ced6;
    border-radius: 0.5rem;
    margin-top: 0.5rem;
    padding: 2rem;
}

.ErrorResetPasswordScreen .box>p {
    margin-top: 1rem;
}

.ErrorResetPasswordScreen .box>p:nth-child(1) {
    margin-top: 0;
}

.ErrorResetPasswordScreen .box div.slds-form-element {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.ErrorResetPasswordScreen .box>div.slds-form-element:nth-child(1) {
    margin-top: 0;
}

.ErrorResetPasswordScreen .box ul {
    list-style: none;
    margin-left: 2rem;
}

.ErrorResetPasswordScreen .box ul li i.bi-check-circle-fill {
    color: green;
}

.ErrorResetPasswordScreen .box ul li i.bi-x-circle-fill {
    color: red;
}

.ErrorResetPasswordScreen .box-caption {
    font-size: 1.75rem;
    font-style: italic;
    text-align: center;
    padding: 0.5rem 0rem;
    font-weight: bold;
}

.ErrorResetPasswordScreen .box-absolute-center {
    margin: auto;
}

.ErrorResetPasswordScreen .icon-email {
    width: 1.25rem;
    height: 1.25rem;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
}

.ErrorResetPasswordScreen .icon-email use {
    fill: gray;
}

.ErrorResetPasswordScreen .remark {
    font-size: 0.75rem;
}

@media only screen and (max-width: 748px) {
    .ErrorResetPasswordScreen {
        width: 100%;
    }
}

/* EmailResetPasswordScreen */
.EmailResetPasswordScreen {
    width: 100%;
}

.EmailResetPasswordScreen .slds-form-element .slds-form-element__label {
    font-weight: bold;
}

.EmailResetPasswordScreen .slds-form-element .slds-form-element__label.required:before {
    content: '* ';
    color: red;
}

.EmailResetPasswordScreen .slds-button_brand {
    background-color: rgb(194, 10, 204);
    border-color: rgb(194, 10, 204);
}

.EmailResetPasswordScreen .slds-button_brand[disabled] {
    background-color: var(--slds-g-color-neutral-base-80, #c9c7c5);
    border-color: var(--slds-g-color-neutral-base-80, #c9c7c5);
    color: var(--slds-g-color-neutral-base-100, white);
}

.EmailResetPasswordScreen .slds-button_outline-brand {
    border-color: rgb(194, 10, 204);
    color: rgb(194, 10, 204);
}

.EmailResetPasswordScreen .box {
    border: 1px solid #c5ced6;
    border-radius: 0.5rem;
    margin-top: 0.5rem;
    padding: 2rem;
}

.EmailResetPasswordScreen .box>p {
    margin-top: 1rem;
}

.EmailResetPasswordScreen .box>p:nth-child(1) {
    margin-top: 0;
}

.EmailResetPasswordScreen .box div.slds-form-element {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.EmailResetPasswordScreen .box>div.slds-form-element:nth-child(1) {
    margin-top: 0;
}

.EmailResetPasswordScreen .box ul {
    list-style: none;
    margin-left: 2rem;
}

.EmailResetPasswordScreen .box ul li i.bi-check-circle-fill {
    color: green;
}

.EmailResetPasswordScreen .box ul li i.bi-x-circle-fill {
    color: red;
}

.EmailResetPasswordScreen .box-caption {
    font-size: 1.75rem;
    font-style: italic;
    text-align: center;
    padding: 0.5rem 0rem;
    font-weight: bold;
}

.EmailResetPasswordScreen .box-absolute-center {
    margin: auto;
}

.EmailResetPasswordScreen .icon-email {
    width: 1.25rem;
    height: 1.25rem;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
}

.EmailResetPasswordScreen .icon-email use {
    fill: gray;
}

.EmailResetPasswordScreen .remark {
    font-size: 0.75rem;
}

@media only screen and (max-width: 748px) {
    .EmailResetPasswordScreen {
        width: 100%;
    }
}

/* VerifySecurityQuestionScreen */
.VerifySecurityQuestionScreen {
    width: 100%;
}

.VerifySecurityQuestionScreen .slds-form-element .slds-form-element__label {
    font-weight: bold;
}

.VerifySecurityQuestionScreen .slds-form-element .slds-form-element__label.required:before {
    content: '* ';
    color: red;
}

.VerifySecurityQuestionScreen .slds-button_brand {
    background-color: rgb(194, 10, 204);
    border-color: rgb(194, 10, 204);
}

.VerifySecurityQuestionScreen .slds-button_brand[disabled] {
    background-color: var(--slds-g-color-neutral-base-80, #c9c7c5);
    border-color: var(--slds-g-color-neutral-base-80, #c9c7c5);
    color: var(--slds-g-color-neutral-base-100, white);
}

.VerifySecurityQuestionScreen .slds-button_outline-brand {
    border-color: rgb(194, 10, 204);
    color: rgb(194, 10, 204);
}

.VerifySecurityQuestionScreen .box {
    border: 1px solid #c5ced6;
    border-radius: 0.5rem;
    margin-top: 0.5rem;
    padding: 2rem;
}

.VerifySecurityQuestionScreen .box>p {
    margin-top: 1rem;
}

.VerifySecurityQuestionScreen .box>p:nth-child(1) {
    margin-top: 0;
}

.VerifySecurityQuestionScreen .box div.slds-form-element {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.VerifySecurityQuestionScreen .box>div.slds-form-element:nth-child(1) {
    margin-top: 0;
}

.VerifySecurityQuestionScreen .box ul {
    list-style: none;
    margin-left: 2rem;
}

.VerifySecurityQuestionScreen .box ul li i.bi-check-circle-fill {
    color: green;
}

.VerifySecurityQuestionScreen .box ul li i.bi-x-circle-fill {
    color: red;
}


.VerifySecurityQuestionScreen .box-caption {
    font-size: 1.75rem;
    font-style: italic;
    text-align: center;
    padding: 0.5rem 0rem;
    font-weight: bold;
}

.VerifySecurityQuestionScreen .box-absolute-center {
    margin: auto;
}

.VerifySecurityQuestionScreen .icon-email {
    width: 1.25rem;
    height: 1.25rem;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
}

.VerifySecurityQuestionScreen .icon-email use {
    fill: gray;
}

.VerifySecurityQuestionScreen .remark {
    font-size: 0.75rem;
}

@media only screen and (max-width: 748px) {
    .VerifySecurityQuestionScreen {
        width: 100%;
    }
}

/* ChangePasswordScreen */
.ChangePasswordScreen {
    width: 100%;
}

.ChangePasswordScreen .slds-form-element .slds-form-element__label {
    font-weight: bold;
}

.ChangePasswordScreen .slds-form-element .slds-form-element__label.required:before {
    content: '* ';
    color: red;
}

.ChangePasswordScreen .slds-button_brand {
    background-color: rgb(194, 10, 204);
    border-color: rgb(194, 10, 204);
}

.ChangePasswordScreen .slds-button_brand[disabled] {
    background-color: var(--slds-g-color-neutral-base-80, #c9c7c5);
    border-color: var(--slds-g-color-neutral-base-80, #c9c7c5);
    color: var(--slds-g-color-neutral-base-100, white);
}

.ChangePasswordScreen .slds-button_outline-brand {
    border-color: rgb(194, 10, 204);
    color: rgb(194, 10, 204);
}

.ChangePasswordScreen .box {
    border: 1px solid #c5ced6;
    border-radius: 0.5rem;
    margin-top: 0.5rem;
    padding: 2rem;
}

.ChangePasswordScreen .box>p {
    margin-top: 1rem;
}

.ChangePasswordScreen .box>p:nth-child(1) {
    margin-top: 0;
}

.ChangePasswordScreen .box div.slds-form-element {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.ChangePasswordScreen .box>div.slds-form-element:nth-child(1) {
    margin-top: 0;
}

.ChangePasswordScreen .box ul {
    list-style: none;
    margin-left: 2em;
}

.ChangePasswordScreen .box ul li i.bi-check-circle-fill {
    color: green;
}

.ChangePasswordScreen .box ul li i.bi-x-circle-fill {
    color: red;
}

.ChangePasswordScreen .box-caption {
    font-size: 1.75rem;
    font-style: italic;
    text-align: center;
    padding: 0.5rem 0rem;
    font-weight: bold;
}

.ChangePasswordScreen .box-absolute-center {
    margin: auto;
}

.ChangePasswordScreen .icon-email {
    width: 1.25rem;
    height: 1.25rem;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
}

.ChangePasswordScreen .icon-email use {
    fill: gray;
}

.ChangePasswordScreen .remark {
    font-size: 0.75rem;
}

@media only screen and (max-width: 748px) {
    .ChangePasswordScreen {
        width: 100%;
    }
}