.hr {
    margin: 1em 0;
    background-color: #c5ced6;
}

.container-form {
    min-width: 420px;
    height: auto;
}

.container-form-heading {
    font-weight: 600;
    font-size: 1.75rem;
    font-style: italic;
}

#right {
    height: 100%;
    flex: 1;
    background-color: pink;
}

.form-login {
    width: 100%;
    margin: auto;
}

.form-login-request-email {
    width: 80%;
    margin: auto;
}

@media only screen and (max-width: 748px) {
    .container-form {
        min-width: unset;
        width: 100%;
    }

    .container-form-heading {
        font-size: 1.5rem;
    }

    .form-login {
        width: 100%;
    }
}

#root>header>div {
    max-width: 100% !important;
}

.form-group>div.slds-form-element {
    margin-bottom: 1em;
}

.form-group {
    margin: 0.5rem 0rem 1.5rem 0rem;
    border: 1px solid #c5ced6;
    border-radius: 0.5rem;
    padding: 2em;
}

.slds-form-element .slds-form-element__label {
    font-weight: bold;
}

.toggle-hide-pass {
    margin-left: -30px;
    cursor: pointer;
}