.ExtensionScreen {
    width: 100%;
}
/* 
.ExtensionScreen #LubeBaseProducts,
.ExtensionScreen #LABIXProducts,
.ExtensionScreen #CustomerOtherProducts,
.ExtensionScreen #PetroleumAndComponent,
.ExtensionScreen #SupplierOtherProducts {
    flex-shrink: 3;
}

.ExtensionScreen #LubeBaseProducts span,
.ExtensionScreen #LABIXProducts span,
.ExtensionScreen #CustomerOtherProducts span,
.ExtensionScreen #PetroleumAndComponent span,
.ExtensionScreen #SupplierOtherProducts span {
    white-space: nowrap;
} */

.ExtensionScreen > div > div > div > div > div > div > div {
    width: 100%;
    margin-top: 10px;
}