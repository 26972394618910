.SetPassword {
    width: 100%;
}

.SetPassword .slds-form-element .slds-form-element__label {
    font-weight: bold;
}

.SetPassword .slds-form-element .slds-form-element__label.required:before {
    content: "* ";
    color: red;
}

.SetPassword .slds-button_brand {
    background-color: rgb(194, 10, 204);
    border-color: rgb(194, 10, 204);
}

.SetPassword .slds-button_outline-brand {
    border-color: rgb(194, 10, 204);
    color: rgb(194, 10, 204);
}

.SetPassword .box {
    border: 2px solid #c5ced6;
    padding: 2em;
}

.SetPassword .box>p {
    margin-top: 1em;
    margin-bottom: 1em;
}

.SetPassword .box>p:nth-child(1) {
    margin-top: 0em;
}

.SetPassword .box>div.slds-form-element {
    margin-top: 1em;
    margin-bottom: 1em;
}

.SetPassword .box>div.slds-form-element:nth-child(1) {
    margin-top: 0;
}

.SetPassword .box ul {
    list-style: disc;
    margin-left: 2em;
}

.SetPassword .box-caption {
    font-size: 1.8em;
    font-style: italic;
    text-align: center;
    padding: 0.5em 0em;
    font-weight: bold;
}

.SetPassword .box-absolute-center {
    margin: auto;
}

.SetPassword .logo {
    width: 40%;
    margin: auto;
    display: block;
}

.SetPassword .logomail {
    width: 10%;
    margin: auto;
    display: block;
}

.SetPassword .hr {
    margin: 1em 0;
    background-color: #c5ced6;
}

.SetPassword .caption {
    font-weight: bold;
    font-size: 120%;
    background-color: #bebebe;
}

.SetPassword .hrVertical {
    border: none;
    border-left: 1px solid hsla(200, 10%, 50%, 100);
    height: 25rem;
}

.SetPassword .tab-container {
    display: flex;
    align-items: center;
    justify-content: center;

}

.SetPassword .tab-container li {
    align-self: center;
}

.SetPassword .buttoncenter {
    text-align: center;
}

.SetPassword .margin-top {
    margin-top: 8px;
}

.SetPassword .toggle-hide-pass {
    margin-left: -30px;
    cursor: pointer;
}