.header-title {
  font-size: 1.5rem;
  font-weight: bold;
  display: inline-block;
  width: 100%;
  text-align: center;
}

header.MuiPaper-root {
  background-color: white;
  box-shadow: none;
  position: sticky;
  z-index: 2;
  top: 0;
}

#notification-popper {
  z-index: 2;
}

div.notification-paper {
  color: white;
  background-color: rgb(0,0,0,0.8);
}

div.notification-paper a {
  color: white;
  text-decoration: none;
}

div.notification-paper li {
  border-top: 1px solid white;
  margin-top: 0.4rem;
  padding-top: 0.4rem;
}
  
.drawer-container {
  background: rgb(0,0,0,0.3);
  display: none;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 64px;
  width: 100vw;
  z-index: 2;
}
  
.drawer-container.open {
  display: block;
}

.drawer {
  background: white;
  height: calc(100% - 64px);
  margin-left: -225px;
  overflow: auto;
  position: fixed;
  transition: margin .25s;
  width: 242px;
  z-index: 2;
}

.drawer.open {
  margin-left: 0px;
}

.drawer a {
  border-left: 4px solid white;
  color: gray;
  display: block;
  font-size: 1.2rem;
  padding: 0.5rem;
}

.drawer a:hover,
.drawer a:focus {
  color: gray;
  text-decoration: none;
}

.drawer a:hover {
  background-color: gray;
  border-left: 4px solid gray;
  color: white;
  text-decoration: none;
}

.drawer a.active {
  border-left: 4px solid rgb(194, 10, 204);
  color: rgb(194, 10, 204);
}

.drawer a.active:hover {
  background-color: rgb(194, 10, 204);
  border-left: 4px solid white;
  color: white;
}

.drawer a i {
  font-size: 1.25rem;
}

.drawer {
  font-size: 1.25rem;
}

.drawer .submenu ul {
  display: none;
}

.drawer .submenu > a::after {
  content: "▼";
  float: right;
  margin-right: 0.5rem;
  text-align: right;
}

.drawer .submenu.open > a::after {
  content: "►";
  float: right;
  margin-right: 0.5rem;
  text-align: right;
}

.drawer .submenu.open ul {
  display: block;
}

.drawer .submenu ul a {
  font-size: 1rem;
  padding-left: 1.5rem;
}

.drawer .submenu .extend-submenu ul {
  display: none;
}

.drawer .submenu .extend-submenu > a::after {
  content: "▼";
  float: right;
  margin-right: 0.5rem;
  text-align: right;
}

.drawer .submenu .extend-submenu.open > a::after {
  content: "►";
  float: right;
  margin-right: 0.5rem;
  text-align: right;
}

.drawer .submenu .extend-submenu.open ul {
  display: block;
}

.drawer .submenu .extend-submenu ul a {
  font-size: 0.8rem;
  padding-left: 2.15rem;
}

.drawer .submenu .document-submenu ul a {
  font-size: 0.8rem;
  padding-left: 2.15rem;
}

