.RequestViewScreen .header {
    display: flex;
    justify-content: space-between;
    margin-left: 250px;
}

.RequestViewScreen .header label {
    font-weight: 700;
}

.RequestViewScreen .header p {
    color: rgb(194, 10, 204);
    font-weight: 700;
}

.RequestViewScreen .path {
    margin-left: 250px;
}

.RequestViewScreen .sidebar {
    background: white;
    min-width: 250px;
    max-width: 250px;
    padding-top: 16px;
    z-index: 1;
}

.RequestViewScreen .main-content {
    width: 100%;
}

.RequestViewScreen .upload-box div {
    text-indent: 0
}

.RequestViewScreen .link-download {
    color: rgb(96, 96, 96);
    font-style: italic;
    text-decoration: none;
}

.RequestViewScreen .link-download svg {
    margin-top: -5px;
}

.RequestViewScreen .MuiTextField-root {
    width: 100%;
}

.RequestViewScreen .MuiFormControl-root {
    width: 100%;
    margin-top: 10px;
}

.RequestViewScreen .MuiPaper-root {
    margin-top: 0;
}

.RequestViewScreen .MuiCardContent-root {
    padding: 0;
}

.RequestViewScreen .Mui-disabled {
    background-color: #f3f3f3;
    -webkit-text-fill-color: rgba(0, 0, 0, 0.75) !important;
}

.RequestViewScreen span.Mui-disabled,
.RequestViewScreen label.Mui-disabled {
    background-color: unset;
}

.RequestViewScreen > div > div > div > div > div > div > div > div {
    width: 100%;
    margin-top: 10px;
}