.PreRegisterScreen {
  width: 100%;
}

.PreRegisterScreen .slds-form-element .slds-form-element__label {
  font-weight: bold;
}

.PreRegisterScreen .slds-checkbox .slds-checkbox__label .slds-form-element__label {
  font-size: 0.75rem;
}

.PreRegisterScreen .slds-form-element .slds-form-element__label.required:before {
  content: "* ";
  color: red;
}

.PreRegisterScreen .help-text+.slds-popover {
  display: none;
}

.PreRegisterScreen .help-text:hover+.slds-popover {
  display: block;
}

/* .PreRegisterScreen .slds-button_brand {
  background-color: rgb(194, 10, 204);
  border-color: rgb(194, 10, 204);
}

.PreRegisterScreen .slds-button_outline-brand {
  border-color: rgb(194, 10, 204);
  color: rgb(194, 10, 204);
} */

.PreRegisterScreen .box {
  border: 1px solid #c5ced6;
  border-radius: 0.25rem;
  padding: 1.25rem;
}

.PreRegisterScreen .box-noborder {
  /* padding: 0 2em; */
}

.PreRegisterScreen .box>p {
  margin-top: 1em;
  margin-bottom: 1em;
}

.PreRegisterScreen .box>p:nth-child(1) {
  margin-top: 0em;
}

.PreRegisterScreen .box>div.slds-form-element {
  margin-top: 1em;
  margin-bottom: 1em;
}

.PreRegisterScreen .box>div.slds-form-element:nth-child(1) {
  margin-top: 0;
}

.PreRegisterScreen .box ul {
  list-style: disc;
  margin-left: 2em;
}

.PreRegisterScreen .box-caption {
  color: #c20acc;
  font-size: 1.5em;
  text-align: center;
  font-weight: bold;
}

.PreRegisterScreen .box-absolute-center {
  margin: auto;
}

.PreRegisterScreen.logomail {
  width: 10%;
  margin: auto;
  display: block;
}

.PreRegisterScreen .hr {
  margin: 1em 0;
  background-color: #c5ced6;
}

.PreRegisterScreen .caption {
  font-weight: bold;
  font-size: 1rem;
  background-color: #bebebe;
  padding: .25rem .25rem .25rem .5rem;
  border-radius: .25rem;
  /* margin: 0 -0.25rem 0 -0.25rem; */
}

.PreRegisterScreen .hrVertical {
  border: none;
  border-left: 1px solid hsla(200, 10%, 50%, 100);
  height: 25rem;
}

.PreRegisterScreen .tab-container {
  display: flex;
  align-items: center;
  justify-content: center;

}

.PreRegisterScreen .tab-container li {
  align-self: center;
}

.PreRegisterScreen.buttoncenter {
  text-align: center;
}

.PreRegisterScreen.display-email {
  font-size: 1.1rem;
}

.PreRegisterScreen .margin-top {
  margin-top: 8px;
}

.PreRegisterScreen fieldset.slds-has-error {
  border: 2px solid #ea001e;
  border-radius: 5px;
}

.PreRegisterScreen fieldset.select-product {
  padding: 5px;
}

.PreRegisterScreen .slds-button_outline-brand.warning {
  color: #f7c325;
  border-color: #fce9b0;
  margin-right: 10px;
}


.PreRegisterScreen .slds-button_outline-brand.warning:hover {
  background-color: #fce9b0;
}