.MainScreen .box-absolute-center {
    margin: auto;
}

.MainScreen .search,
.MainScreen_modal-title .search {
    border: 1px solid #c9c9c9;
    border-radius: 2rem;
    padding: 0.25rem 0.25rem 0.25rem 2.25rem;
}

.MainScreen_modal-title .search {
    color: black;
}

.MainScreen .bi-check-circle-fill {
    color: green;
}

.MainScreen .slds-input__icon {
    z-index: 0;
}

.MainScreen th {
    text-align: center;
}

.MainScreen td:nth-child(2),
.MainScreen td:nth-child(3),
.MainScreen td:nth-child(4),
.MainScreen td:nth-child(5),
.MainScreen td:nth-child(6) {
    text-align: center;
}

.MainScreen_modal-title {
    color: #b0b0b0;
}

.MainScreen_modal-title svg {
    fill: #b0b0b0;
    font-size: 1.5rem;
}

.MainScreen_modal-body .slds-form-element__label {
    font-size: 0.9rem;
}

.MainScreen_modal-body i {
    font-size: 1.2rem;
}

.MainScreen_modal-body .slds-button_outline-brand {
    color: var(
        --slds-c-button-text-color,
        var(--sds-c-button-text-color, #0176d3)
    );
    border-color: var(
        --slds-c-button-outline-brand-color-border,
        var(--sds-c-button-outline-brand-color-border, #0176d3)
    );
}