.App {
  display: block;
}

.logo-header,
.logo-header-no-login {
  height: 40px;
}

.logo-header-no-login {
  padding-left: 25px;
}

.help-text+.slds-popover {
  display: none;
}

.help-text:hover+.slds-popover {
  display: block;
}

.MuiSvgIcon-root.help-text {
  transform: rotate(180deg)
}

.slds-button__icon_large {
  width: 1.25rem;
  height: 1.25rem;
}

@media only screen and (max-width: 748px) {
  .App-header {
    padding: 1.25rem;
  }

  .logo-header {
    display: none;
  }

  .logo-header-no-login {
    margin: auto;
  }
}

.App-header {
  display: flex;
  background-color: #fff;
  width: 100%;
  /* align-items: center; */
  justify-content: center;
  padding: 1.25rem 0.25rem;
  min-height: calc(100vh - 180px);
}

.App-body {
  height: 100%;
}

.App-footer {
  background-color: #fff;
  position: static;
  left: 0;
  bottom: 65px;
  width: 100%;
  height: 65px;
  color: white;
  text-align: center;
  padding-top: 65px;
}

html {
  scroll-behavior: smooth;
}

#root>div.App>div>div>div>div>span>span>span {
  font-weight: bold;
  color: black;
}

#root>div.App>div>div>div>div>div>div>div>div {
  width: 100%;
  margin-top: 10px;
}

#root > div.App > div > div > div.MuiStack-root.css-1xkohyh-MuiStack-root > div.MuiBox-root.css-0 > div > div.MuiSnackbar-root.MuiSnackbar-anchorOriginTopRight.css-1arijsr-MuiSnackbar-root > div > div.MuiAlert-icon.css-1ytlwq5-MuiAlert-icon{
  width: 5% !important;
}
#root > div.App > div > div > div.MuiStack-root.css-1xkohyh-MuiStack-root > div.MuiBox-root.css-0 > div > div.MuiSnackbar-root.MuiSnackbar-anchorOriginTopRight.css-1arijsr-MuiSnackbar-root > div > div.MuiAlert-action.css-ki1hdl-MuiAlert-action{
  width: 10% !important;
}

[data-input-text="text-input-form"] input {
  height: 7px;
}

.upload-box {
  border: 2px dashed #8b8b8b;
}

.ctr-button_primary {
  background-color: #c20acc;
  border-color: #c20acc;
  color: white;
}

.ctr-button_primary:active {
  background-color: #c20acc;
  border-color: #c20acc;
  color: white;
  opacity: 0.5;
  box-shadow: 0px 0px 10px 0px #c20acc;
}

.ctr-button_primary-outline,
.ctr-button_primary-outline:hover {
  background-color: white;
  border-color: #c20acc;
  color: #c20acc;
}

.ctr-button_primary-outline:active {
  background-color: #c20acc;
  border-color: #c20acc;
  color: white;
  opacity: 0.5;
  box-shadow: 0px 0px 10px 0px #c20acc;
}

div.field-required>div {
  width: 100%;
}

.field-required .MuiInputBase-root input,
.field-required .MuiSelect-select {
  border-left: 3px solid red;
  border-radius: 3px;
}

.facility .MuiFormGroup-row label {
  width: 100%;
}

.MuiStepper-root {
  position: relative;
  z-index: 0;
}